import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import Utils from './utils'
const Base64 = require('js-base64').Base64
import Vant from 'vant'
import 'vant/lib/index.css'



axios.defaults.baseURL='http://localhost:8090/api/'


Vue.use(ElementUI);
Vue.use(Vant)

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.apiuri = "https://api.aicopay.com/api.php"
Vue.prototype.$utils = Utils;
Vue.prototype.Base64 = Base64;
Vue.prototype.aeskey = "xUioWyktZ2qxKdLEcEoaHl3MvdEKuLb7"

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
