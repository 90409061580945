<script>
export default {
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      loading: true,
      alipay: true,
      wxpay: true,
      bestpay: true,
      qrhash: "",
      shopinfo: [],
      input: "",
      price: 0,
      payloading: false,
      remark: "",
      showremark: false
    };
  },
  mounted() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.match(/Alipay/i) != "alipay") this.alipay = false;
    if (userAgent.match(/MicroMessenger/i) != "micromessenger")
      this.wxpay = false;
    if (userAgent.match(/Bestpay/i) != "micromessenger") this.bestpay = false;
    this.qrhash = this.$route.params.hash;
    if (this.qrhash) this.getShopInfo();
  },
  methods: {
    getShopInfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "qrcode.shopinfo",
          },
          {
            headers: { Qrtoken: that.qrhash },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.shopinfo = response.data.data;
            document.title = "付款到" + that.shopinfo.name
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    tapPrice(e) {
      this.input = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.price = this.input * 100;
      // this.input = this.money(this.input)
      
    },
    makePaymentOrder() {
      var that = this;
      if (that.price > 0) {
        that.payloading = true;
        var payway = "alipay";
        if (that.wxpay) payway = "wxpay";
        if (that.bestpay) payway = "bestpay";

        that.$axios
          .post(
            that.apiuri,
            {
              action: "qrcode.makeorder",
              price: that.price / 100,
              payway: payway,
              remark: that.remark,
            },
            {
              headers: { Qrtoken: that.qrhash },
            }
          )
          .then(function (response) {
            that.payloading = false;
            if (response.data.status == 200) {
              window.location.href = response.data.url;
            } else {
              if (response.data.msg) {
                that.$alert(response.data.msg, "提示", {
                  confirmButtonText: "确定",
                });
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        that.$message.error("请输入金额");
      }
    },
    addRemark() {
      this.showremark = true
    },
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ".00"; //预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <van-nav-bar title="付款" />
    <el-row>
      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <el-avatar
            shape="square"
            :size="60"
            :src="shopinfo.cover"
          ></el-avatar></div
      ></el-col>
      <el-col :span="14"
        ><div class="grid-content bg-purple shoptitle">
          <h3>{{ shopinfo.name }}</h3>
        </div></el-col
      >
    </el-row>
    <div>
      <input
        class="price-input"
        v-model="input"
        @input="tapPrice"
        type="number"
        autofocus="autofocus"
        placeholder="0.00"
      />
    </div>
    <div class="demo-input-suffix" v-if="showremark">
      <el-input
        placeholder="输入备注信息"
        maxlength="50"
        show-word-limit
        v-model="remark">
      </el-input>
    </div>
    <van-submit-bar
      :price="price"
      :loading="payloading"
      button-text="付款"
      @submit="makePaymentOrder"
    >
      <template #tip>
        <label @click="addRemark"
          >请核对商户信息，如需标记订单请点此添加备注信息</label
        >
      </template>
    </van-submit-bar>
  </div>
</template>
<style>
.demo-input-suffix{
  margin-top: 65px;
}
</style>